import { reactive } from 'vue';

const activityStoreKey = Symbol('activityStoreKey');

interface activityStore {
  hasTranTemporary: boolean;
  succeedCreateTemporary(): void;
  failedCreateTemporary(): void;
}

const useActivityStore = () => {
  const state = reactive({
    hasTranTemporary: false,
  });
  return {
    get hasTranTemporary() {
      return state.hasTranTemporary;
    },
    succeedCreateTemporary: () => {
      state.hasTranTemporary = true;
    },
    failedCreateTemporary: () => {
      state.hasTranTemporary = false;
    },
  };
};

export { type activityStore, useActivityStore, activityStoreKey };
